import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment.dev';
import { map, Observable } from "rxjs";
import { BaseService } from "../../@core/services/base.service";
import { IUser, ResponseJson } from "../../@core/models/model";
import { USER_ROLE, USER_TYPE } from "../../@core/models/enums";
import { AuthenticationService, ILogin } from "../../@core/services/authentication.service";


@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {

    constructor(
        protected override http: HttpClient,
        private authService: AuthenticationService
    ) {
        super(http);
    }

    usersUrl = environment.apiUrl + '/user'

    getUsers() {
        return this.get<IUser[]>(this.usersUrl + '/list')
    }

    getCurrentUser(): Observable<ResponseJson<IUser>> {
        return this.get<IUser>(this.usersUrl + '/profile');
    }

    updateWalkThrough(data: { type: string, status: boolean }) {
        return this.post<any>(this.usersUrl + '/update-product-walkthrough', data).pipe(map(data => {
            return this.authService.setUserData(data.data)
        }))
    }
    updateProfile(userData: FormData): Observable<ResponseJson<{ user: IUser, token: ILogin['token'] }>> {
        return this.put<{ user: IUser, token: ILogin['token'] }>(this.usersUrl + '/profile', userData).pipe(
            map(res => {
                if (res.success) {
                    const { user, token } = res.data;
                    this.authService.setUserData({ token });
                }
                return res;
            })
        );
    }

    inviteUser(data: {
        email: string,
        role: USER_ROLE
    }[]) {
        return this.post(this.usersUrl + '/invite', data)
    }

    deleteUser(userId: string) {
        return this.delete(this.usersUrl + '/delete/' + userId)
    }

    updateUser(user: IUser) {
        return this.put<IUser>(this.usersUrl + '/', { user })
    }

    getUserDetailsByEmail(email: string) {
        return this.get<{
            user: IUser,
            type: USER_TYPE
        }>(this.usersUrl + '/user-details-by-email', { email })
    }
}
